import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Stack,
  Grid,
  CircularProgress,
  Box,
  FormControlLabel,
} from "@mui/material";
import HomeLayout from "../../asset/layout/HomeLayout";
import { Add } from "@mui/icons-material";

import Paginations from "../../components/mui/Pagination";
import CustomButton from "../../components/mui/CustomButton";
import RModal from "../../components/mui/RModal";
import InputField1 from "../../components/mui/InputField1";
import { enqueueSnackbar } from "notistack";

import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import {
  deleteBackenUser,
  getAllAdminUsers,
  updateUser,
  userAdd,
} from "../../actions/backEndUserAuth";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import DropDownMenu from "../../components/mui/DropDownMenu";
import SelectDropdown from "../../components/mui/SelectDropdown";
import IOSSwitch from "../../style/globalStyles";
import SearchBar from "../../components/mui/SearchBar";
import ResponsiveDatePickers from "../../components/mui/PichDate";
import dayjs from "dayjs";
const columns = [
  "User Name",
  "Email",
  "Number",
  "Role",
  "Last Login Date",
  "Action",
];

const categories = [
  { name: "Select the Role", value: "Select the Role" },
  { name: "Order management", value: "Order management" },
  { name: "Brand management", value: "Brand management" },
  { name: "Client management", value: "Client management" },
];
export const BackendUser = () => {
  const [open, setOpen] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const { user } = useSelector((state) => ({ ...state }));
  const [selectedCategory, setSelectedCategory] = useState(
    categories[0]?.value || ""
  );

  const allUsers = useSelector((state) => state.allUsers);
  console.log("all user is", allUsers);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const roleDropdownRef = useRef(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Navigate("/");
    }
  }, []);

  useEffect(() => {
    console.log("User ID:", user);
  }, [user]);

  const userId = user.id;

  const fetchAdminUsers = () => {
    setLoading(true);
    dispatch(
      getAllAdminUsers(
        { page: currentPage, perPage: countPerPage },
        userId,
        (data) => {
          const filteredData = data.data.data.users.filter((item) => {
            const matchQuery =
              item?.username
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.gender
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) ||
              item?.phone?.includes(searchTerm) ||
              item?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
              item?.lastLogin
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());

            const matchDateRange =
              (!dateFrom || new Date(item.updatedAt) >= new Date(dateFrom)) &&
              (!dateTo || new Date(item.updatedAt) <= new Date(dateTo));

            return matchQuery && matchDateRange;
          });

          filteredData.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setUsers(filteredData);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    setLoading(true);
    fetchAdminUsers();
  }, [dispatch, countPerPage, currentPage, searchTerm, dateFrom, dateTo]);

  const [formDataEdit, setFormDataEdit] = useState({
    email: "",
    phone: "",
    username: "",
    hasAccessTo: "",
  });

  const [formDataAdd, setFormDataAdd] = useState({
    email: "",
    phone: "",
    username: "",
    hasAccessTo: "",
  });

  const handleOpen = (user) => {
    setOpen(true);
    if (user) {
      const _id = user.id;
      setFormDataEdit({
        username: user.username,
        phone: user.phone,
        email: user.email,
        hasAccessTo: user.hasAccessTo,
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenSecondModal = () => {
    setOpenSecondModal(true);
  };

  const handleCloseSecondModal = () => {
    setOpenSecondModal(false);
  };

  const handleCouponDataChange = (name, value) => {
    setFormDataEdit((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCouponDataChange1 = (name, value) => {
    if (name === "hasAccessTo") {
      setFormDataAdd((prevData) => ({
        ...prevData,
        hasAccessTo: value,
      }));
    } else {
      setFormDataAdd((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setLoading(true);

    dispatch(
      updateUser(
        userId,
        formDataEdit,

        (response) => {
          enqueueSnackbar("Data submitted successfully", {
            variant: "success",
          });

          fetchAdminUsers();

          handleClose();
          setLoading(false);
        },
        (error) => {
          console.log(error);
          enqueueSnackbar(
            error?.response?.data?.message,

            {
              variant: "error",
            }
          );
        }
      )
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    dispatch(
      userAdd(
        userId,
        formDataAdd,
        (response) => {
          enqueueSnackbar("Data submitted successfully", {
            variant: "success",
          });

          setLoading(false);
          handleCloseSecondModal();
          fetchAdminUsers();
          setSelectedCategory(categories[0]?.value || "");
        },
        (error) => {
          enqueueSnackbar(
            error?.response?.data?.message || "Error submitting data",
            {
              variant: "error",
            }
          );
          console.log(error);
        }
      )
    );
  };
  const handleReset = () => {
    setFormDataAdd({
      email: "",
      phone: "",
      username: "",
      hasAccessTo: "",
    });
    setSelectedCategory(categories[0]?.value || "");
    if (roleDropdownRef.current) {
      roleDropdownRef.current.reset();
    }
  };
  const handleDelete = (id) => {
    dispatch(
      deleteBackenUser(
        userId,
        id,

        {},
        (data) => {
          enqueueSnackbar("Delete Successfully", {
            variant: "success",
          });
          fetchAdminUsers();
        },
        (err) => {
          enqueueSnackbar("Error Occured! Please try again", {
            variant: "error",
          });
        }
      )
    );
  };

  const handleExport = () => {
    const formattedData = users.map((user) => ({
      Username: user.username,
      Email: user.email,
      Phone: user.phone,
      Role: user.hasAccessTo,
      "Last Login Date": new Date(user.updatedAt).toLocaleDateString("en-IN"),
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");

    XLSX.writeFile(wb, "BackendUsers.xlsx");
  };

  const updateUserStatus = (id, status, email) => {
    console.log("email", email);
    const payload = {
      active: status,
      email: email,
    };

    dispatch(
      updateUser(
        userId,
        { id, ...payload },
        (response) => {
          enqueueSnackbar("User status updated successfully", {
            variant: "success",
          });
          fetchAdminUsers();
        },
        (error) => {
          enqueueSnackbar("Error updating user status", {
            variant: "error",
          });
          console.error(error);
        }
      )
    );
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleSearchClear = () => {
    setSearchTerm("");
    setDateFrom(null);
    setDateTo(null);
  };

  const paginatedData = users.slice(
    (currentPage - 1) * countPerPage,
    currentPage * countPerPage
  );

  return (
    <>
      <Stack alignItems="center" direction={"row"} mt={5}>
        <Typography
          variant="h4"
          my={3}
          flexGrow={1}
          fontSize={{ xl: "22px", sm: "1rem", xs: "1rem" }}
        >
          User Management
        </Typography>
        <Box pr={2}>
          {" "}
          <CustomButton
            variant="contained"
            onClick={handleExport}
            fontSize={{ xl: "1rem", sm: ".7rem", xs: ".7rem" }}
          >
            Export Backend Users
          </CustomButton>
        </Box>
        <CustomButton
          variant="contained"
          onClick={handleOpenSecondModal}
          fontSize={{ xl: "1rem", sm: ".7rem", xs: ".7rem" }}
        >
          {" "}
          <Add sx={{ mr: { xl: 1, sm: 0.2 } }} />
          Add User
        </CustomButton>
      </Stack>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Grid item xs={4}>
          <SearchBar
            onSearch={handleSearch}
            onClear={handleSearchClear}
            searchTerm={searchTerm}
          />
        </Grid>

        {/* <Grid item md={3} xs={12}>
          <ResponsiveDatePickers
            selectedDate={dateFrom}
            setSelectedDate={setDateFrom}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ResponsiveDatePickers
            selectedDate={dateTo}
            setSelectedDate={setDateTo}
          />
        </Grid> */}
        <Grid item md={2} xs={12}>
          {" "}
          <CustomButton
            variant="contained"
            onClick={handleSearchClear}
            width={"100%"}
            height={"2.5rem"}
          >
            clear
          </CustomButton>
        </Grid>
      </Grid>
      {loading ? (
        <Box align="center" my={5}>
          <CircularProgress size={24} />
        </Box>
      ) : paginatedData.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "25px",
            my: 3,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {users.map((user, index) => (
                <TableRow key={user.id}>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {user.username}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {user.email}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    +91{user.phone}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {user.hasAccessTo ? user.hasAccessTo : "--"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {user?.updatedAt
                      ? dayjs(user?.updatedAt).format("MM-DD-YYYY")
                      : "--"}
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <IconButton onClick={() => handleDelete(user?.id)}>
                      <img src="/liDelet.svg" alt="liDelet" />
                    </IconButton>
                    <IconButton onClick={() => handleOpen(user)}>
                      <img src="/liEdit.svg" alt="liEdit" />
                    </IconButton>
                    <FormControlLabel
                      style={{ paddingLeft: 8 }}
                      control={
                        <IOSSwitch
                          checked={user.active}
                          onChange={(e) => {
                            updateUserStatus(
                              user.id,
                              e.target.checked,
                              user.email
                            );
                          }}
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", my: 3 }}>
          No results found.
        </Typography>
      )}
      {/* <Paginations /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography>
            {(currentPage - 1) * countPerPage + 1} -{" "}
            {Math.min(currentPage * countPerPage, users.length)} of{" "}
            {users.length}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Rows per page:</Typography>
          <select
            style={{
              marginTop: "-5px",
              background: "transparent",
              border: "none",
            }}
            value={countPerPage}
            onChange={(e) => setCountPerPage(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          <Box sx={{ ml: 2 }}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1 || countPerPage > users.length}
                sx={{
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "mute.main",
                  bgcolor: "white.main",
                  boxShadow: "2px 2px 0px #999",
                  background: "#fff",
                }}
              >
                <KeyboardArrowLeftIcon
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color:
                      currentPage === 1 || countPerPage > users.length
                        ? "#999"
                        : "#000",
                  }}
                />
              </IconButton>
              <Typography variant="text" component="span">
                {currentPage}/{Math.ceil(users.length / countPerPage)}
              </Typography>
              <IconButton
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={
                  countPerPage > users.length ||
                  currentPage === Math.ceil(users.length / countPerPage)
                }
                sx={{
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "mute.main",
                  bgcolor: "white.main",
                  boxShadow: "2px 2px 0px #999",
                  background: "#fff",
                }}
              >
                <KeyboardArrowRightIcon
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Box>

      {open && (
        <RModal
          open={open}
          handleClose={handleClose}
          width={{ xl: "60%", sm: "80%", xs: "80%" }}
          height="auto"
        >
          <form onSubmit={handleEdit}>
            <Typography variant="h4">Update Admin </Typography>

            <Grid container spacing={{ xl: 1, sm: 0, xs: 0 }}>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"User Name"}
                  placeholder={"Enter User Name"}
                  spanP={true}
                  value={formDataEdit.username}
                  onChange={(e) =>
                    handleCouponDataChange("username", e.target.value)
                  }
                  required
                  maxLength={20}
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Email"}
                  placeholder={"Enter Email"}
                  spanP={true}
                  value={formDataEdit.email}
                  onChange={(e) =>
                    handleCouponDataChange("email", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Mobile Number"}
                  placeholder={"Enter Mobile Number"}
                  spanP={true}
                  value={formDataEdit.phone}
                  onChange={(e) =>
                    handleCouponDataChange("phone", e.target.value)
                  }
                  required
                  maxLength={10}
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <Typography mt={1.5} mb={1.5}>
                  Role
                </Typography>
                <SelectDropdown
                  ref={roleDropdownRef}
                  menuItems={categories.map((item) => ({
                    value: item.value,
                    label: item.name,
                  }))}
                  selectedItem={formDataEdit.hasAccessTo}
                  setSelectedItem={setSelectedCategory}
                  buttonWidth={"100%"}
                  height={"2.7rem"}
                  borderRadius={".5rem"}
                  onChange={(selectedValue) =>
                    handleCouponDataChange("hasAccessTo", selectedValue)
                  }
                />
              </Grid>
            </Grid>
            <Stack
              direction={"row"}
              spacing={{ xl: 6, md: 6, sm: 1, xs: 1 }}
              mt={4}
            >
              {" "}
              <CustomButton
                variant="contained"
                width={{
                  xl: "20vh",
                  md: "50%",
                  sm: "50%",
                  xs: "60%",
                }}
                height={"3rem"}
                type="submit"
              >
                {loading ? <CircularProgress size={24} /> : "Update "}
              </CustomButton>
              {/* <CustomButton
                variant='outlined'
                width={{ xl: '20vh', md: '50%', sm: '50%', xs: '40%' }}
                height={'3rem'}
                type='reset'
              >
                Reset
              </CustomButton> */}
            </Stack>
          </form>
        </RModal>
      )}
      {openSecondModal && (
        <RModal
          open={openSecondModal}
          handleClose={handleCloseSecondModal}
          width={{ xl: "60%", sm: "80%", xs: "80%" }}
          height="auto"
        >
          <form onSubmit={handleSubmit}>
            <Typography variant="h4">Add Admin</Typography>

            <Grid container spacing={{ xl: 1, sm: 0, xs: 0 }}>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"User Name"}
                  placeholder={"Enter User Name"}
                  spanP={true}
                  onChange={(e) =>
                    handleCouponDataChange1("username", e.target.value)
                  }
                  required
                  maxLength={20}
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Email"}
                  placeholder={"Enter Email"}
                  spanP={true}
                  onChange={(e) =>
                    handleCouponDataChange1("email", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <InputField1
                  placeholderColor=" #8D98AA"
                  label={"Mobile Number"}
                  placeholder={"Enter Mobile Number"}
                  spanP={true}
                  onChange={(e) =>
                    handleCouponDataChange1("phone", e.target.value)
                  }
                  required
                  maxLength={10}
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12}>
                <Typography mt={1.5} mb={1.5}>
                  Role
                </Typography>
                <SelectDropdown
                  ref={roleDropdownRef}
                  menuItems={categories.map((item) => ({
                    value: item.value,
                    label: item.name,
                  }))}
                  selectedItem={selectedCategory}
                  setSelectedItem={setSelectedCategory}
                  buttonWidth={"100%"}
                  height={"2.7rem"}
                  borderRadius={".5rem"}
                  onChange={(selectedValue) =>
                    handleCouponDataChange1("hasAccessTo", selectedValue)
                  }
                />
              </Grid>
            </Grid>
            <Stack
              direction={"row"}
              spacing={{ xl: 6, md: 6, sm: 1, xs: 1 }}
              mt={4}
            >
              <CustomButton
                variant="contained"
                width={{
                  xl: "20vh",
                  md: "50%",
                  sm: "50%",
                  xs: "60%",
                }}
                height={"3rem"}
                type="submit"
              >
                {loading ? <CircularProgress size={24} /> : "Add"}
              </CustomButton>
              <CustomButton
                variant="outlined"
                width={{
                  xl: "20vh",
                  md: "50%",
                  sm: "50%",
                  xs: "40%",
                }}
                height={"3rem"}
                type="reset"
                onClick={handleReset}
              >
                Reset
              </CustomButton>
            </Stack>
          </form>
        </RModal>
      )}
    </>
  );
};
