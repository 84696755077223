import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  RechargeRequestById,
  updateRechargeStatus,
} from "../../actions/clientsAuth";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import CustomButton from "../../components/mui/CustomButton";
import SearchBar from "../../components/mui/SearchBar";
import ResponsiveDatePickers from "../../components/mui/PichDate";
import dayjs from "dayjs";
import DropDownMenu from "../../components/mui/DropDownMenu";
import { enqueueSnackbar } from "notistack";

const columns = [
  "Transaction Receipt",
  "Amount",
  "Bank Account Number",
  "Transaction Date",
  "Transaction Id",
  "Transaction Mode",
  "Created On",
  "Status",
];

const RechargeRequests = () => {
  const [loading, setLoading] = useState(false);
  const [rechargeData, setRechargeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({
    status: "",
    reason: "",
    id: "",
  });

  const adminId = localStorage.getItem("User id");
  const dispatch = useDispatch();
  const { id: companyId } = useParams();

  const getData = () => {
    setLoading(true);
    dispatch(
      RechargeRequestById(
        companyId,
        (data) => {
          setRechargeData(data.data.data);
          setLoading(false);
        },
        (error) => {
          console.log("Error:", error);
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [dispatch, companyId]);

  useEffect(() => {
    applyFilters();
  }, [searchTerm, selectedDateFrom, selectedDateTo, rechargeData]);

  const applyFilters = () => {
    let filtered = rechargeData;

    if (searchTerm) {
      filtered = filtered.filter((row) => {
        const bankAccountMatch = row?.bankAccountNumber
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        const transactionIdMatch = row?.transactionId
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        const transactionModeMatch = row?.transactionMode
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());

        return bankAccountMatch || transactionIdMatch || transactionModeMatch;
      });
    }

    if (selectedDateFrom) {
      filtered = filtered.filter((row) => {
        // Parse the `transactionDate` as a Day.js object
        const transactionDate = dayjs(row?.transactionDate);
        return (
          transactionDate.isSame(selectedDateFrom, "day") ||
          transactionDate.isAfter(selectedDateFrom, "day")
        );
      });
    }

    if (selectedDateTo) {
      filtered = filtered.filter((row) => {
        // Parse the `transactionDate` as a Day.js object
        const transactionDate = dayjs(row?.transactionDate);
        return (
          transactionDate.isSame(selectedDateTo, "day") ||
          transactionDate.isBefore(selectedDateTo, "day")
        );
      });
    }

    setFilteredData(filtered);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleSearchClear = () => {
    setSearchTerm("");
    setSelectedDateFrom(null);
    setSelectedDateTo(null);
  };

  const handleStatusChange = (id, newStatus) => {
    setOpenModal(true);
    setModalData({ id, status: newStatus, reason: "" });
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setModalData({ status: "", reason: "" });
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    const formData = {
      adminId: adminId,
      remark: modalData?.reason,
      status: modalData?.status,
    };

    dispatch(
      updateRechargeStatus(
        modalData.id,
        formData,
        (response) => {
          enqueueSnackbar("Data submitted successfully", {
            variant: "success",
          });
          handleModalClose();
          setLoading(false);
          getData();
        },
        (error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
          console.error(error);
          handleModalClose();
        }
      )
    );
  };
  console.log(rechargeData);
  return (
    <Fragment>
      <Stack alignItems="center" direction={"row"}>
        <Typography
          variant="h4"
          my={1}
          flexGrow={1}
          fontSize={{ xl: "22px", sm: "1rem", xs: "1rem" }}
        ></Typography>
      </Stack>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <SearchBar onSearch={handleSearch} searchTerm={searchTerm} />
          </Grid>
          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateFrom}
              setSelectedDate={setSelectedDateFrom}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ResponsiveDatePickers
              selectedDate={selectedDateTo}
              setSelectedDate={setSelectedDateTo}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <CustomButton
              variant="contained"
              onClick={handleSearchClear}
              width={"100%"}
              height={"2.5rem"}
            >
              Clear
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Box align="center" my={5}>
          <CircularProgress size={24} />
        </Box>
      ) : filteredData.length > 0 ? (
        <TableContainer component={Paper} sx={{ borderRadius: "25px", my: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {filteredData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography
                      component="a"
                      href={row?.transactionReceipt}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: "#11409B",
                        textDecoration: "underline",
                        cursor: "pointer",
                        display: "block",
                        marginTop: "8px",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      View Document
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row?.amount}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row?.bankAccountNumber}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row?.transactionDate
                      ? dayjs(row?.transactionDate).format("MM-DD-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row?.transactionId}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row?.transactionMode}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row?.createdAt
                      ? dayjs(row.createdAt).format("MM-DD-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <DropDownMenu
                      buttonText="Change The Status"
                      menuItems={[
                        { label: "UNDER-VERIFICATION" },
                        { label: "VERIFIED" },
                        { label: "REJECTED" },
                      ]}
                      selectedItem={row?.status}
                      setSelectedItem={(label) =>
                        handleStatusChange(row?._id, label)
                      }
                      buttonWidth={"100%"}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography textAlign="center" my={3} fontSize={"1rem"}>
          No Data Found
        </Typography>
      )}

      {/* Status Modal */}
      <Modal open={openModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" mb={2}>
            Confirm Status Change
          </Typography>
          <Typography variant="body1" mb={2}>
            Are you sure you want to change the status to "{modalData.status}"?
          </Typography>
          {modalData.status === "REJECTED" && (
            <TextField
              label="Reason for rejection"
              fullWidth
              multiline
              rows={3}
              value={modalData.reason}
              onChange={(e) =>
                setModalData({
                  ...modalData,
                  reason: e.target.value,
                })
              }
              variant="outlined"
              sx={{ mb: 2 }}
            />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              onClick={handleModalClose}
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
            <Button
              onClick={handleModalSubmit}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default RechargeRequests;
